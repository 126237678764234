import {HTTP} from '@/libs/https.js';
import config from '@/libs/config.js';
class CampusSafetyDormitoryModel extends HTTP{
    CampusSafetySaveSetting(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.CAMPUS_SAFETY_SAVE_SETTING,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    CampusSafetyGetSetting(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.CAMPUS_SAFETY_GET_SETTING,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
}
export {CampusSafetyDormitoryModel};
